header {
	width: 100%;
	height: 115px;
	padding: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		width: 75px;
	}
}
