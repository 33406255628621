@import '../variables';

.error-message {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	section {
		font-size: 0.85rem;
		margin-top: 1.5rem;
		text-align: left;

		span {
			text-indent: 1.5rem;
			display: inline-block;
			margin-bottom: 0.5rem;
		}
	}

	.sentence {
		font-size: 1rem;
		margin-bottom: 2rem;
	}

	.divider {
		background-color: $bright-red;
		height: 1px;
		width: 200px;
		margin: 1rem 0;
	}
}
