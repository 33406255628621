.button {
	border: 1px solid rgb(68, 0, 0);
	background-color: rgb(133, 0, 0);
	color: white;
	min-width: 170px;
	padding: 1rem;
	border-radius: 0.6rem;
	font-size: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;

	&:disabled {
		opacity: 0.5;
	}

	.button-icon {
		width: 28px;
		height: 100%;
		margin-right: auto;
		margin-left: 0.2rem;

		& img {
			color: white;
		}
	}
	.button-label {
		margin-right: auto;
	}
}
