@import '../../variables';

.modal-content-wrap {

	.modal-header {
		font-weight: 700;
		color: $dark-red;
		font-size: 1.3rem;

	}
	.modal-inner-content {
		p {
			font-size: 1rem;
		}
	}
}
