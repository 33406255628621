.form {
	background-color: white;
	border-radius: 0.6rem;
	box-shadow: 0 0 12px 2px rgb(0, 0, 0);

	fieldset {
		padding: 1rem 2rem;
		border: none;

		.field-pair {
			display: flex;

			& .checkbox {
				width: 50%;
			}

			@media screen and (max-width: 500px) {
				display: block;

				& .checkbox {
					margin: auto;
					width: 100%;
					text-align: center;

				}
			}
		}
	}

	.controls {
		padding: 1.2rem;
		border-top: 2px solid #de2020;
		display: flex;
		justify-content: center;
	}

	.input-group {
		width: 100%;
		margin-bottom: 1rem;

		.input-label {
			padding: 0.3rem;
			width: 100%;
			text-align: left;
			font-size: 0.9rem;
			font-weight: 700;
			color: #960a0a;
		}

		input {
			width: 100%;
			border-radius: 0.3rem;
			border: 1px solid #ccc;
			padding: 0.75rem;
			text-align: center;
			font-size: 1.2rem;
		}

		&.checkbox {
			.input {
				width: 15px;
				padding: 0.75rem;
				padding-left: 0;

				input {
					display: none;
				}

				button {
					border: none;
					background-color: transparent;
					color: #960a0a;
				}
			}
		}
	}

}
