@import '../../variables';

.sign-in-form {
	width: 70%;
	max-width: 600px;
	min-width: 300px;

	@media screen and (max-width: 600px) {
		width: 80%;
	}

	h4.curr-table {
		color: #960a0a;
		font-size: 1rem;
		margin-top: 0;
		display: flex;
		justify-content: center;
		align-items: center;

		span {
			padding: 0.4rem;
			background-color: $dark-red;
			color: white;
			width: 40px;
			height: 40px;
			display: flex;
			border-radius: 50%;
			text-align: center;
			justify-content: center;
			align-items: center;
			margin-left: 0.4rem;
		}
	}

	.groupname-label {
		color: #960a0a;
		font-weight: 700;
		text-align: left;
		font-size: 1rem;
		font-style: italic;
		padding: 0.5rem;
		margin: 0.75rem 0;

		border-bottom: 1px solid #de2020;
	}

	.agreement-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		section {
			font-size: 0.85rem;
			margin-top: 1.5rem;
			text-align: left;

			span {
				text-indent: 1.5rem;
				display: inline-block;
				margin-bottom: 0.5rem;
			}
		}

		.agreement-sentence {
			font-size: 1rem;
			margin-bottom: 2rem;
		}

		.divider {
			background-color: $bright-red;
			height: 1px;
			width: 200px;
			margin: 1rem 0 ;
		}

	}
}
