/* Box sizing rules */
*,
*::before,
*::after {
	box-sizing: border-box;
}

.js-loading *,
.js-loading *:before,
.js-loading *:after {
	animation-play-state: paused !important;
}

body,
html {
	padding: 0;
	margin: 0;
}

.App {
	font-family: "Lato";
  text-align: center;
	background-color: rgb(24, 24, 24);
	background-image: url('assets/main-bg.jpg');
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}
